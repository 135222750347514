import React from 'react';

const MortgageConsider = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>The type of mortgage depends on your circumstances and goals not just the interest rates</li>
              <li>
              There are a few things to ask yourself when deciding what type of mortgage is right your you:
              <ol>
                <li>Do you want your mortgage to be paid off at the end of the term?</li>
                <li>How much can you afford in monthly mortgage repayments?</li>
                <li>How often do you want to review or amend your mortgage?</li>
                <li>Do you want to make overpayments and how much in overpayments?</li>
              </ol>
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
            What type of mortgage deal should I consider?<br/><br/>
            A mortgage that is right for one person might not necessarily be right for another person.<br/><br/>
            It's not just about getting the best rate on your mortgage, but about structuring the mortgage in a way that suits your personal circumstances and achieves your personal goals.<br/><br/>
            The kind of questions you might ask yourself, or even better, discuss with a mortgage adviser are:<br/><br/>
            Do I want the certainty that the mortgage will be repaid at the end of the term?<br/><br/>
            If not, how are you going to pay that mortgage back?<br/><br/>
            Are you going to be happy selling that property to repay the mortgage?<br/><br/>
            Also, consider how much do you want to spend on the mortgage each month?<br/><br/>
            And do you mind if your payments fluctuate?<br/><br/>
            Or would you prefer to know exactly how much you're due to pay each month?<br/><br/>
            How often would you like the ability to review and make amendments to your mortgage?<br/><br/>
            And, would you like the ability to make unlimited overpayments?<br/><br/>
            Lastly, when would you like to be mortgage-free if your employment is servicing the mortgage?<br/><br/>
            How long do you want to work for?<br/><br/>
            What are your retirement plans?<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default MortgageConsider;
